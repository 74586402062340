import * as Sentry from '@sentry/nextjs';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import getUrlByEnv from '@utils/get-url-by-env';
import decoratePageContent from '@utils/decorate-page-content';
import getNav from '@framework/api/operations/get-nav';
import getPage from '@framework/api/operations/get-page';
import { STAGING, REVALIDATE_VALUES } from '@constants';
import Head from '@components/Head';
import Layout from '@components/Layout';
import Page from '@components/Page';

export const getStaticProps: GetStaticProps = async ({
  locale,
  locales,
  preview,
}) => {
  const envName =
    process.env.VERCEL_GITHUB_COMMIT_REF === STAGING
      ? process.env.NEXT_PUBLIC_STAGING_ENV_NAME
      : process.env.NEXT_PUBLIC_ENV_NAME;

  const baseUrl = getUrlByEnv(envName);

  const [page, nav] = await Promise.all([
    getPage({ slug: 'home', locale, preview }),
    getNav({ slug: 'main', locale, preview }),
  ]);

  if (!page) {
    Sentry.captureException(new Error(`Dato Page with slug 'home' not found`));

    return {
      notFound: true,
      revalidate: REVALIDATE_VALUES.notFound,
    };
  }

  const { content, ...rest } = page;
  const decoratedContent = await decoratePageContent(content);
  const newPage = { content: decoratedContent, ...rest };

  if (!newPage?.slug) {
    Sentry.captureException(
      new Error(`Dato error with home page in ${locale}`)
    );

    return {
      notFound: true,
      revalidate: REVALIDATE_VALUES.fallback,
    };
  }

  return {
    props: {
      preview: preview || false,
      envName,
      baseUrl,
      locale,
      locales: JSON.stringify(locales),
      page: newPage,
      nav,
    },
    revalidate: REVALIDATE_VALUES.standard,
  };
};

const Home: InferGetStaticPropsType<typeof getStaticProps> = ({
  baseUrl,
  page,
}) => (
  <>
    <Head baseUrl={baseUrl} canonical={`${baseUrl}`} cmsData={page.seo} />
    <Page page={page} />
  </>
);

Home.Layout = Layout;

export default Home;
