/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo, useMemo } from 'react';
import { getRegion } from '@framework/api/utils/maxify';
import { useRouter } from 'next/router';
import { useTranslation as t } from '@utils/hooks';
import IconDynamic from '@components/IconDynamic';
import {
  IconTestedwithCare,
  Icon2YearWarranty,
  IconExpertlyBuilt,
  IconHeart,
  IconStep1,
  IconStep2,
  IconStep3,
  IconGameSync,
  IconSmartScheduling,
  IconCompleteCustomization,
  IconRBGLEDStrips,
  IconSideGlasscasepanel,
  IconAerFanRGB,
  IconH1,
  IconKrakenZ,
  IconKrakenzpumps,
  IconKraken,
  IconLighting,
  IconRadiatorSupportLarge,
  IconRadiatorSupport,
  IconSettings2,
  IconIntegratedRearIO,
  IconPerformance2,
  IconRGBCableComb,
  IconKrakenM22,
  IconReinforcedExtendedTubing,
  IconWifi,
  IconMidTower,
} from '@nzxt/react-icons';
import type { DatoIcon } from '@framework/api/types';
import ButtonLink from '@components/ButtonLink';
import * as styles from './styles';

type Props = {
  data: DatoIcon[];
  hasBg?: boolean;
  isSmall?: boolean;
};

// to do: icons need updating

const IconSection: FC<Props> = ({ data, isSmall, hasBg }) => {
  const { locale } = useRouter();

  const region = getRegion(locale);

  const regionalIconData = useMemo(
    () =>
      Array.isArray(data)
        ? data.filter(c => c.availableRegions?.some(r => r?.region === region))
        : null,
    [data, region]
  );

  const iconData = regionalIconData?.length > 0 ? regionalIconData : data;

  const { buildSystem } = styles;
  const ICON_SECTION_MAP = {
    Heart: <IconHeart className={buildSystem(isSmall)} />,
    TestedwithCare: <IconTestedwithCare className={buildSystem(isSmall)} />,
    TwoYearWarranty: <Icon2YearWarranty className={buildSystem(isSmall)} />,
    ExpertlyBuilt: <IconExpertlyBuilt className={buildSystem(isSmall)} />,
    Step1: <IconStep1 className={buildSystem(isSmall)} />,
    Step2: <IconStep2 className={buildSystem(isSmall)} />,
    Step3: <IconStep3 className={buildSystem(isSmall)} />,
    GameSync: <IconGameSync className={buildSystem(isSmall)} />,
    SmartScheduling: <IconSmartScheduling className={buildSystem(isSmall)} />,
    RBGLEDStrips: <IconRBGLEDStrips className={buildSystem(isSmall)} />,
    CompleteCustomization: (
      <IconCompleteCustomization className={buildSystem(isSmall)} />
    ),
    SideGlasscasepanel: (
      <IconSideGlasscasepanel className={buildSystem(isSmall)} />
    ),
    AerFanRGB: <IconAerFanRGB className={buildSystem(isSmall)} />,
    H1: <IconH1 className={buildSystem(isSmall)} />,
    KrakenZ: <IconKrakenZ className={buildSystem(isSmall)} />,
    Krakenzpumps: <IconKrakenzpumps className={buildSystem(isSmall)} />,
    Kraken: <IconKraken className={buildSystem(isSmall)} />,
    RadiatorSupportLarge: (
      <IconRadiatorSupportLarge className={buildSystem(isSmall)} />
    ),
    RadiatorSupport: <IconRadiatorSupport className={buildSystem(isSmall)} />,
    Lighting: <IconLighting className={buildSystem(isSmall)} />,
    Settings2: <IconSettings2 className={buildSystem(isSmall)} />,
    RearIO: <IconIntegratedRearIO className={buildSystem(isSmall)} />,
    Performance2: <IconPerformance2 className={buildSystem(isSmall)} />,
    RGBCableComb: <IconRGBCableComb className={buildSystem(isSmall)} />,
    KrakenM22: <IconKrakenM22 className={buildSystem(isSmall)} />,
    Reinforced: (
      <IconReinforcedExtendedTubing className={buildSystem(isSmall)} />
    ),
    Wifi: <IconWifi className={buildSystem(isSmall)} />,
    MidTower: <IconMidTower className={buildSystem(isSmall)} />,
  };

  const LEARN_LABEL = t('learn_more');

  return Array.isArray(iconData) && iconData?.length > 0 ? (
    <div className={styles.iconSection(hasBg)}>
      {iconData?.map(item => (
        <div
          className={styles.iconSectionItem(isSmall)}
          key={item.text}
          data-test-id="icon-subblock"
        >
          {item.iconName ? (
            <IconDynamic
              iconName={item.iconName}
              className={buildSystem(isSmall)}
            />
          ) : (
            ICON_SECTION_MAP[item.iconComponent]
          )}
          <div className={styles.textContainer}>
            {item.title && (
              <h3
                className={styles.heading(isSmall)}
                data-test-id="icon-block-title"
              >
                {item.title}
              </h3>
            )}
            <p className={styles.text(isSmall)} data-test-id="icon-block-text">
              {item.text}
            </p>
            {item.link && (
              <ButtonLink href={item.link}>{LEARN_LABEL}</ButtonLink>
            )}
          </div>
        </div>
      ))}
    </div>
  ) : null;
};

export default memo(IconSection);
