import { classnames } from '@tailwindcss-classnames';

const smallSizes = classnames('w-12', 'h-12', 'lg:w-8', 'lg:h-8');
const largeSizes = classnames('w-12', 'h-12');

export const buildSystem = (isSmall: boolean): string =>
  classnames(
    { [smallSizes]: isSmall, [largeSizes]: !isSmall },
    'text-nzxt-dark-grey-500',
    'fill-current',
    'mx-auto',
    'col-span-1'
  );

const sectionBg = classnames(
  'bg-nzxt-light-grey-100',
  'lg:rounded-md',
  'py-8',
  'lg:py-12',
  'px-4',
  'w-full',
  'lg:max-w-screen-lg',
  'xl:max-w-screen-xl',
  '2xl:max-w-screen-2xl',
  'mx-auto',
  'lg:px-10',
  'xl:px-20',
  'mx-auto'
);

export const iconSection = (hasBg: boolean): string =>
  classnames(
    'flex',
    'flex-col',
    'md:flex-row',
    'justify-center',
    'space-y-6',
    'md:space-y-0',
    'md:space-x-4',
    { [sectionBg]: hasBg }
  );

const centerSmall = classnames('items-center', 'md:items-end');

export const iconSectionItem = (isSmall: boolean): string =>
  classnames(
    { [centerSmall]: isSmall },
    'md:w-1/3',
    'px-2',
    'md:px-0',
    'md:pt-0',
    'w-full',
    'md:text-center',
    'md:flex',
    'md:flex-col',
    'items-center',
    'md:space-y-2',
    'grid',
    'grid-cols-4'
  );

const smallText = classnames('text-xs', 'px-4');
const defultText = classnames('text-sm', 'md:text-base', 'px-2');
const deafultHeaderText = classnames('text-xs', 'md:text-lg', 'px-2');

export const text = (isSmall: boolean): string =>
  classnames('text-nzxt-dark-grey-500', {
    [smallText]: isSmall,
    [defultText]: !isSmall,
  });
export const heading = (isSmall: boolean): string =>
  classnames('text-nzxt-dark-grey-800', 'font-semibold', {
    [smallText]: isSmall,
    [deafultHeaderText]: !isSmall,
  });

const withBg = classnames('bg-nzxt-light-grey-100');
const noBg = classnames('bg-transparent');
export const getBg = (hasBg: boolean): string =>
  classnames({ [withBg]: hasBg, [noBg]: !hasBg });

export const textContainer = classnames(
  'col-span-3',
  'md:col-span-1',
  'md:mx-auto'
);
